<template>
  <div>
    <v-breadcrumbs
      :items="pathModul"
      divider="-"
      normal
      class="pathModuls"
    ></v-breadcrumbs>

    <v-container class="container-filtering">
      <v-row align="center">
        <!-- Column Select Station -->
        <v-col class="col-input-filter">
          <v-select
            v-on:change="selectStation"
            :items="itemStation"
            item-value="station_id"
            item-text="name"
            label="Station"
          ></v-select>
        </v-col>

        <!-- Column Select Calendar -->
        <v-col class="col-input-filter">
          <v-menu
            v-model="modalCalendar"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="valueFromDatet"
                label="From Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="valueFromDatet"
              v-on:change="selectFromDate"
              @input="modalCalendar = false"
            >
              <v-spacer></v-spacer>
            </v-date-picker>
          </v-menu>
        </v-col>

        <!-- Column Select Calendar2 -->
        <v-col class="col-input-filter">
          <v-menu
            v-model="modalCalendar2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="valueToDatet"
                label="To Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="valueToDatet"
              v-on:change="selectToDate"
              @input="modalCalendar2 = false"
            >
              <v-spacer></v-spacer>
            </v-date-picker>
          </v-menu>
        </v-col>

        <!-- Column Generate Button -->
        <v-col cols="12" sm="6" md="4" class="col-btn-generateReport">
          <v-btn
            color="#4495D1"
            class="ma-2 white--text btn-generate primary"
            solo
            dense
            normal
            @click="getTransmission"
          >
            Generate Report
            <v-icon right dark big>
              mdi-table-arrow-up
            </v-icon>
          </v-btn>

          <v-btn color="green" elevation="2" @click="exportReportTrans">
            <v-icon color="white" center dark>
              mdi-microsoft-excel
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-subheader
        class="subTitles fontSubTitle-blue"
        v-if="queryValuet == true"
      >
        <!-- TRANSMISSION ALERT RECORD FOR {{ valueStationt }} ({{ valueFromDatet }} TO {{ valueToDatet }}) -->
        Transmission Alert Record For {{ valueStationt }} ({{
          this.changeDateFormat(valueFromDatet)
        }}
        To {{ this.changeDateFormat(valueToDatet) }})
      </v-subheader>

      <v-data-table
        :headers="thead_alerttransmission"
        :items="tbody_alerttransmission"
        :items-per-page="5"
        class="elevation-1 headerDtSarawak"
        :custom-sort="customSort"
        :loading="loadingTable"
      >
        <!-- <template v-slot:header="{ props: { headers } }">
            <thead>
                <tr>
                    <th v-for="(h,key) in headers" 
                    :key="key"
                    divider='true'
                    class="table-header">
                    <span>{{h.text}}</span>
                    </th>
                </tr>
            </thead>
        </template> -->

        <!-- <template v-slot:[`item.notes`]="props">
          <v-edit-dialog
            :return-value.sync="props.item.notes"
            large
            persistent
            @save="saveTrans"
            @cancel="cancelTrans"
            @open="openTrans"
            @close="closeTrans"
          >
            <div>{{ props.item.notes }}</div>
            <template v-slot:input>
              <div class="mt-4 text-h6">
                Update Notes
              </div>
              <v-text-field
                v-model="props.item.notes"
                label="Edit"
                single-line
                counter
                autofocus
                @input="updateNotesTrans= { newId: props.item.newId, notes: $event}"
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template> -->
      </v-data-table>

      <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
        {{ snackText }}

        <template v-slot:action="{ attrs }">
          <v-btn v-bind="attrs" text @click="snack = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
// import XLSX from 'xlsx';
import Papa from "papaparse";

export default {
  data: () => ({
    pathModul: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Alert - Transmission Alert Record",
        disabled: true,
        href: "/Alert/Transmission",
      },
    ],
    itemStation: [],
    // date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    // date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    menu: false,
    modalCalendar: false,
    menu2: false,
    modalCalendar2: false,

    loadingTable: false,
    thead_alerttransmission: [
      {
        text: "STATION ID",
        value: "stationId",
        divider: true,
        align: "center",
        sortable: false,
      },
      { text: "DATETIME", value: "datetime", divider: true, align: "center" },
      {
        text: "DATETIME RECEIVED",
        value: "receivedDt",
        divider: true,
        align: "center",
      },
      { text: "NOTES", value: "notes", divider: true, align: "center" },
      //   { text: 'UPDATED BY', value: 'notesBy', divider: true, align: 'center' },
      {
        text: "UPDATED ON",
        value: "notesUpdatedDt",
        divider: true,
        align: "center",
      },
    ],
    tbody_alerttransmission: [],

    valueStationt: null,
    valueFromDatet: new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10),
    valueToDatet: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    queryValuet: false,

    snack: false,
    snackColor: "",
    snackText: "",
    pagination: {},
    updateNotesTrans: {
      newId: "",
      notes: "",
    },
  }),

  methods: {
    selectStation(stationId) {
      // console.log(stationId);
      this.valueStationt = stationId;
    },

    selectFromDate(fromDate) {
      // console.log(fromDate);
      this.valueFromDatet = fromDate;
    },

    selectToDate(toDate) {
      // console.log(toDate);
      this.valueToDatet = toDate;
    },

    changeDateFormat(date) {
      var dd = date.split("-")[2];
      var mm = date.split("-")[1];
      var yyyy = date.split("-")[0];
      date = dd + "/" + mm + "/" + yyyy;
      return date;
    },

    changeDatetimeFormat(datetime) {
      if (datetime != "" && datetime != null) {
        let [date, time] = datetime.split("T");

        //convert date
        var dd = date.split("-")[2];
        var mm = date.split("-")[1];
        var yyyy = date.split("-")[0];
        date = dd + "/" + mm + "/" + yyyy;

        // Convert Time to 12 hours
        var H = +time.substr(0, 2);
        var h = H % 12 || 12;
        var ampm = H < 12 ? "AM" : "PM";
        time = h + time.substr(2, 3) + time.substr(5, 3) + ampm;

        datetime = date + ", " + time;
        return datetime;
      } else {
        return (datetime = null);
      }
    },

    getStationTransmission() {
      axios
        .get("https://apis.spatialworks.com.my/sarawak/cms/stations", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          let stationInfo = [];
          // let stationInfo = [];

          response.data.forEach(function(res) {
            let objStation = {};
            objStation["station_id"] = res.stationId;
            objStation["name"] = res.stationId + " - " + res.location;
            stationInfo.push(objStation);
          });

          this.itemStation = stationInfo;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getTransmission() {
      this.loadingTable = true;
      axios
        .get(
          "https://apis.spatialworks.com.my/sarawak/cms/transmission?station=" +
            this.valueStationt +
            "&startdt=" +
            this.valueFromDatet +
            "&enddt=" +
            this.valueToDatet,
          {
            headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
            },
          }
        )
        .then((response) => {
          this.tbody_alerttransmission = response.data;
          for (let i = 0; i < response.data.length; i++) {
            response.data[i].datetime = this.changeDatetimeFormat(
              response.data[i].datetime
            );
            response.data[i].receivedDt = this.changeDatetimeFormat(
              response.data[i].receivedDt
            );
            response.data[i].notesUpdatedDt = this.changeDatetimeFormat(
              response.data[i].notesUpdatedDt
            );
          }
          this.queryValuet = true;
          this.loadingTable = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingTable = false;
        });
    },

    // open modal notes
    saveTrans() {
      const that = this;
      axios
        .put(
          `https://apis.spatialworks.com.my/sarawak/cms/transmission`,
          this.updateNotesTrans,
          {
            headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          that.getTransmission();
        })
        .catch((error) => {
          console.log(error);
        });
      console.log(this.updateNotesTrans);
      this.snack = true;
      this.snackColor = "success";
      this.snackText = "Data saved";
    },
    cancelTrans() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Canceled";
    },
    openTrans() {
      this.snack = true;
      this.snackColor = "info";
      this.snackText = "Dialog opened";
    },
    closeTrans() {
      console.log("Dialog closed");
    },

    exportReportTrans() {
      // const data = XLSX.utils.json_to_sheet(this.tbody_alerttransmission);
      // const wb = XLSX.utils.book_new();
      // XLSX.utils.book_append_sheet(wb, data, 'data');
      // XLSX.writeFile(wb,'Transmission Alert Record For ' + this.valueStationt + ' (' + this.changeDateFormat(this.valueFromDatet) + ' TO ' + this.changeDateFormat(this.valueToDatet) + ').xlsx');
      // console.log(this.tbody_alerttransmission);
      let headerTrans = [];
      for (let i = 0; i < this.thead_alerttransmission.length; i++) {
        headerTrans.push(this.thead_alerttransmission[i].value);
      }

      let keysTrans = headerTrans,
        result = this.tbody_alerttransmission.map((o) =>
          Object.assign(...keysTrans.map((k) => ({ [k]: o[k] })))
        );

      console.log(result);

      var blob = new Blob([Papa.unparse(result)], {
        type: "text/csv;charset=utf-8;",
      });

      var link = document.createElement("a");

      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        "Transmission Alert Record For " +
          this.valueStationt +
          " (" +
          this.changeDateFormat(this.valueFromDatet) +
          " TO " +
          this.changeDateFormat(this.valueToDatet) +
          ").csv"
      );
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    customSort: function(items, index, isDesc) {
      //   console.log(items, index, isDesc);

    //   console.log(index);

      items.sort((a, b) => {
        if (
          index[0] == "datetime" ||
          index[0] == "receivedDt" ||
          index[0] == "notesUpdatedDt"
        ) {
          //   console.log(new Date(this.standardDtFormatNuzul(b[index])));
          if (!isDesc[0]) {
            return (
              new Date(this.standardDtFormatNuzul(b[index])) -
              new Date(this.standardDtFormatNuzul(a[index]))
            );
          } else {
            return (
              new Date(this.standardDtFormatNuzul(a[index])) -
              new Date(this.standardDtFormatNuzul(b[index]))
            );
          }
        } else {
          if (typeof a[index] !== "undefined") {
            if (!isDesc[0]) {
              return a[index]
                .toLowerCase()
                .localeCompare(b[index].toLowerCase());
            } else {
              return b[index]
                .toLowerCase()
                .localeCompare(a[index].toLowerCase());
            }
          }
        }
      });
      return items;
    },
  },

  mounted() {
    this.getStationTransmission();
  },
};
</script>

<style lang="scss">
@import "~scss/main";

.pathModuls {
  padding-bottom: 0px;
}

.pathModuls > li:nth-child(3) > a {
  color: black !important;
}

.container-filtering {
  padding: 0px;
}

.col-input-filter {
  padding: 0px 10px;
}

.col-btn-generateReport {
  margin-bottom: 18px;
}

.btn-generate {
  margin: 0px !important;
}

.container-filtering::v-deep .v-text-field.v-text-field--solo .v-input__control,
.btn-generate .v-btn .v-btn--contained {
  min-height: 10px;
  margin-top: 10px;
}

.subTitles {
  margin-left: 28%;
  // color: #1976D2 !important;
}

// .table-header {
//     white-space: nowrap;
//     padding: 16px !important;
//     // background: #4495D1;
//     // color: white !important;
//     text-align: center !important;
//     border: 1px solid rgba(0, 0, 0, 0.12);
// }
</style>