var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-breadcrumbs',{staticClass:"pathModuls",attrs:{"items":_vm.pathModul,"divider":"-","normal":""}}),_c('v-container',{staticClass:"container-filtering"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"col-input-filter"},[_c('v-select',{attrs:{"items":_vm.itemStation,"item-value":"station_id","item-text":"name","label":"Station"},on:{"change":_vm.selectStation}})],1),_c('v-col',{staticClass:"col-input-filter"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"From Date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.valueFromDatet),callback:function ($$v) {_vm.valueFromDatet=$$v},expression:"valueFromDatet"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modalCalendar),callback:function ($$v) {_vm.modalCalendar=$$v},expression:"modalCalendar"}},[_c('v-date-picker',{on:{"change":_vm.selectFromDate,"input":function($event){_vm.modalCalendar = false}},model:{value:(_vm.valueFromDatet),callback:function ($$v) {_vm.valueFromDatet=$$v},expression:"valueFromDatet"}},[_c('v-spacer')],1)],1)],1),_c('v-col',{staticClass:"col-input-filter"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"To Date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.valueToDatet),callback:function ($$v) {_vm.valueToDatet=$$v},expression:"valueToDatet"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modalCalendar2),callback:function ($$v) {_vm.modalCalendar2=$$v},expression:"modalCalendar2"}},[_c('v-date-picker',{on:{"change":_vm.selectToDate,"input":function($event){_vm.modalCalendar2 = false}},model:{value:(_vm.valueToDatet),callback:function ($$v) {_vm.valueToDatet=$$v},expression:"valueToDatet"}},[_c('v-spacer')],1)],1)],1),_c('v-col',{staticClass:"col-btn-generateReport",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-btn',{staticClass:"ma-2 white--text btn-generate primary",attrs:{"color":"#4495D1","solo":"","dense":"","normal":""},on:{"click":_vm.getTransmission}},[_vm._v(" Generate Report "),_c('v-icon',{attrs:{"right":"","dark":"","big":""}},[_vm._v(" mdi-table-arrow-up ")])],1),_c('v-btn',{attrs:{"color":"green","elevation":"2"},on:{"click":_vm.exportReportTrans}},[_c('v-icon',{attrs:{"color":"white","center":"","dark":""}},[_vm._v(" mdi-microsoft-excel ")])],1)],1)],1)],1),_c('v-container',[(_vm.queryValuet == true)?_c('v-subheader',{staticClass:"subTitles fontSubTitle-blue"},[_vm._v(" Transmission Alert Record For "+_vm._s(_vm.valueStationt)+" ("+_vm._s(this.changeDateFormat(_vm.valueFromDatet))+" To "+_vm._s(this.changeDateFormat(_vm.valueToDatet))+") ")]):_vm._e(),_c('v-data-table',{staticClass:"elevation-1 headerDtSarawak",attrs:{"headers":_vm.thead_alerttransmission,"items":_vm.tbody_alerttransmission,"items-per-page":5,"custom-sort":_vm.customSort,"loading":_vm.loadingTable}}),_c('v-snackbar',{attrs:{"timeout":3000,"color":_vm.snackColor},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snack = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snack),callback:function ($$v) {_vm.snack=$$v},expression:"snack"}},[_vm._v(" "+_vm._s(_vm.snackText)+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }